import { MhcTopicCategoryEnum } from "graphqlApi/types";

import { titleize } from "common/util/helpers";
import { TopicPage } from "modules/Topics/util/topicIndexStaticProps";

export const filterItems = (
  items: TopicPage[],
  condition: (topic: TopicPage) => boolean
): TopicPage[] => {
  return items.reduce((acc: TopicPage[], item: TopicPage) => {
    if (condition(item) && !item.children?.length) {
      acc.push(item);
    }
    if (item.children?.length) {
      const filteredChildren = filterItems(item.children, condition);
      if (filteredChildren.length > 0) {
        acc.push({ ...item, children: filteredChildren } as TopicPage);
      }
    }
    return acc;
  }, []);
};

export const categoryFormatter = (category: MhcTopicCategoryEnum) => {
  return titleize(category.replace(/_/g, " "));
};
