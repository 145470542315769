import DashboardIcon from "@mui/icons-material/Dashboard";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import PeopleIcon from "@mui/icons-material/People";
import PieChartIcon from "@mui/icons-material/PieChart";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { SvgIcon } from "@mui/material";

import { MhcCategoryIconEnum } from "graphqlApi/types";

export type CustomMuiComponentPropType =
  | keyof JSX.IntrinsicElements
  | React.JSXElementConstructor<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

export const MhcIconTypeMap: Record<string, typeof SvgIcon> = {
  [MhcCategoryIconEnum.Dashboard]: DashboardIcon,
  cpr: NaturePeopleIcon,
  ecdc: PeopleIcon,
  stories: RemoveRedEyeIcon,
  dataDictionary: PieChartIcon
};
