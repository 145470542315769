"use client";

import React, { useCallback, useMemo } from "react";
import { useRouter } from "next/navigation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Link as MuiLink,
  SxProps,
  TextFieldProps,
  Theme
} from "@mui/material";
import debounce from "lodash/debounce";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";

import { MhcLocationFragment } from "graphqlApi/types";

import { geographyName } from "common/util/geographyHelpers";
import { GaEventCategories, GaEventTypes, sendGaEvent } from "common/util/googleAnalytics";
import { titleize } from "common/util/helpers";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";
import { GeographyNumberValueEncoding } from "common/util/sortGeographiesBySize";

import { LoadingInput } from "../Loading/LoadingInput";
import { LocationPickerInput } from "./LocationPickerInput";

export interface LocationPickerProps {
  locations: Array<MhcLocationFragment>;
  currentLocation?: MhcLocationFragment | null;
  groupLocations?: boolean;
  onChange: (value: MhcLocationFragment) => void;
  onMouseDown?: (value: MhcLocationFragment) => void;
  label?: string;
  hiddenLabel?: boolean;
  sx?: SxProps;
  inputSize?: AutocompleteRenderInputParams["size"];
  trackGaEvent?: boolean;
  sortFromLargerToSmaller?: boolean;
  disabled?: boolean;
  inputPlaceholder?: string;
  inputSx?: TextFieldProps["sx"];
  selectedLocationId?: string;
}

const isOptionEqualToValue = (a: MhcLocationFragment, b: MhcLocationFragment) => a?.id === b?.id;

export const baseAutoCompleteSX: SxProps<Theme> = {
  px: { xs: 0 },
  mt: 2,
  mb: 4,
  minWidth: "100%"
};

export const baseAutoCompleteProps = {
  disablePortal: true,
  disableClearable: true,
  id: `location-picker`,
  fullWidth: true
};

export const LocationPicker: React.FC<LocationPickerProps> = ({
  groupLocations = false,
  locations,
  currentLocation,
  onChange,
  onMouseDown,
  sx,
  label = "Selected Location",
  trackGaEvent = true,
  sortFromLargerToSmaller = false,
  disabled = false,
  hiddenLabel,
  inputPlaceholder,
  inputSx,
  selectedLocationId
}) => {
  const router = useRouter();
  // sort names alphabetically with state always first
  const display = useMemo(() => {
    const sorted = sortBy(locations, (l) => GeographyNumberValueEncoding[l.geography] ?? 0);
    if (sortFromLargerToSmaller === true) {
      return sorted.reverse();
    }
    return sorted;
  }, [locations, sortFromLargerToSmaller]);

  const { pathWithParamKeys, params } = useRouterPath();

  const handleChange = useCallback(
    (evt: React.SyntheticEvent<Element, Event>, value: MhcLocationFragment | null) => {
      value &&
        trackGaEvent &&
        sendGaEvent({
          eventType: GaEventTypes.Navigation,
          parameters: {
            category: "Location Picker",
            action: "Select",
            label: value?.name ?? "Location"
          }
        });
      !isNil(value) && onChange(value);
    },
    [onChange, trackGaEvent]
  );
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <LocationPickerInput
        hiddenLabel={hiddenLabel}
        label={label}
        placeholder={inputPlaceholder}
        inputSx={inputSx}
        {...params}
      />
    ),
    [hiddenLabel, label, inputPlaceholder, inputSx]
  );

  if (!locations || locations.length === 0) {
    return <LoadingInput height={100} />;
  }

  return (
    <Autocomplete
      {...baseAutoCompleteProps}
      // Declaring this as a string is a workaround for a bug where
      // no string is rendered despite there being a value
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultValue={currentLocation?.name ?? ""}
      value={currentLocation}
      options={display}
      getOptionLabel={(option: MhcLocationFragment) => {
        if (!option && currentLocation) return currentLocation.name;
        return option?.name ?? "";
      }}
      onChange={handleChange}
      sx={{
        ...baseAutoCompleteSX,
        ...sx
      }}
      renderInput={renderInput}
      isOptionEqualToValue={isOptionEqualToValue}
      popupIcon={<ExpandMoreIcon />}
      groupBy={
        groupLocations
          ? (option) => titleize(geographyName(option.geography, { pluralize: true }))
          : undefined
      }
      renderOption={({ style, ...props }, option) => {
        const selected = option.id === selectedLocationId;
        return (
          <li
            style={{
              ...style,
              backgroundColor: selected ? "rgba(2, 136, 209, 0.035)" : style?.backgroundColor,
              color: selected ? "#0060F0" : style?.color,
              display: "flex"
            }}
            {...props}
          >
            <MuiLink
              component={"button"}
              onMouseDown={() => {
                if (onMouseDown) {
                  onMouseDown(option);
                  return;
                }
                const { locationId, ..._params } = params;
                if (locationId) return;
                const newPath = pathWithParamKeys.replace("[locationId]", option.id);
                Object.entries(_params).forEach(([key, value]) => {
                  newPath.replace(`[${key as string}]`, value as string);
                });
                if (newPath) router.prefetch(newPath);
              }}
              underline="none"
              sx={{
                display: "block",
                width: "100%",
                height: "100%",
                flex: 1,
                textAlign: "left"
              }}
            >
              {option.name}
            </MuiLink>
          </li>
        );
      }}
      onInputChange={debounce((event, value) => {
        if (event?.type === "change" && value) {
          sendGaEvent({
            eventType: GaEventTypes.Search,
            parameters: {
              category: GaEventCategories.LocationPicker,
              action: "Search",
              label: value
            }
          });
        }
      }, 1000)}
      disabled={disabled}
    />
  );
};
