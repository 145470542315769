import { MhcGeographyEnum } from "graphqlApi/types";

export const devStaticPathIds: Partial<Record<MhcGeographyEnum, string[]>> = {
  [MhcGeographyEnum.State]: ["state"],
  [MhcGeographyEnum.County]: ["county-kent"],
  [MhcGeographyEnum.ZipCode]: ["zip-code-19802"],
  [MhcGeographyEnum.CensusTract]: ["census-tract-432-02"],
  [MhcGeographyEnum.SchoolDistrict]: ["school-district-laurel"],
  [MhcGeographyEnum.Municipality]: ["dover"]
};
