import { AutocompleteRenderInputParams, InputLabel, Stack, TextFieldProps } from "@mui/material";

import { MhcTextField } from "../MhcTextField";

interface Props extends AutocompleteRenderInputParams {
  label: string;
  hiddenLabel?: boolean;
  placeholder?: string;
  inputSx?: TextFieldProps["sx"];
}

const PickerInput: React.FC<Props> = ({
  label,
  hiddenLabel,
  InputProps,
  placeholder,
  inputSx,
  ...params
}) => {
  const htmlId = `location-picker-input`;
  const htmlLabelId = `${htmlId}-label`;
  return (
    <Stack>
      {label && <InputLabel htmlFor={htmlId}>{label}</InputLabel>}
      <MhcTextField
        margin="none"
        hiddenLabel={hiddenLabel}
        placeholder={placeholder}
        htmlId={htmlId}
        {...params}
        fullWidth
        sx={inputSx}
        InputProps={{
          ...InputProps,
          "aria-label": label ?? "Item picker"
        }}
        InputLabelProps={{
          htmlFor: htmlId,
          id: htmlLabelId
        }}
      />
    </Stack>
  );
};

export default PickerInput;
