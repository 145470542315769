import { MhcGeographyEnum } from "graphqlApi/types";

export const SUPPORTED_CPR_LOCATION_GEOGRAPHIES = [
  MhcGeographyEnum.ZipCode,
  MhcGeographyEnum.CensusTract
] as MhcGeographyEnum[];

export const CPR_NAME = "Community Profile Report";

export const CPR_SHORT_URL = "https://myhealthycommunity.dhss.delaware.gov/cpr/";

export const shortCprUrl = (locationId: string) => CPR_SHORT_URL + locationId;

export const CPR_REPORT_DATE_STATE_KEY = "cpr-latest-date";
