import React from "react";

import { TopicPage } from "modules/Topics/util/topicIndexStaticProps";

import TopicTableRow from "./TopicTableRow";

const TopicTableChildren = ({
  childTopics = [],
  level = 2
}: {
  childTopics?: TopicPage[];
  level: number;
}) => {
  if (!childTopics.length) return null;
  return (
    <>
      {childTopics.map((topic, i) => (
        <React.Fragment key={topic.name ?? i}>
          <TopicTableRow {...topic} isChild key={topic.name ?? i} level={level} />
          <TopicTableChildren childTopics={topic.children as TopicPage[]} level={level + 1} />
        </React.Fragment>
      ))}
    </>
  );
};

export default TopicTableChildren;
