"use client";

import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Link as MuiLink, Stack, Typography } from "@mui/material";

import { TopicPage } from "modules/Topics/util/topicIndexStaticProps";

import MhcTooltip from "common/components/MhcTooltip";

const TableCellContent: React.FC<Partial<TopicPage>> = ({ href, name, description, parent }) => {
  return (
    <>
      <MuiLink component="a" href={href}>
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography>{name}</Typography>
          {description && (
            <MhcTooltip title={description}>
              <InfoIcon fontSize="inherit" color="action" />
            </MhcTooltip>
          )}
        </Stack>
      </MuiLink>
      {parent && (
        <Typography variant="body2" color="light.secondary">
          {" "}
          ({parent})
        </Typography>
      )}
    </>
  );
};

export default TableCellContent;
