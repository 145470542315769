import { MhcFeatureCollection, MhcLocationFragment } from "graphqlApi/types";

export const createFeatureCollectionFromGeoJsons = (locations: MhcLocationFragment[]) => {
  return locations
    .map(({ mapData }) => {
      if (mapData?.type === "Feature") {
        return mapData;
      }
      if (mapData?.type === "FeatureCollection") {
        return mapData.features;
      }
      return undefined;
    })
    .flat()
    .filter(
      (mapData) => mapData !== undefined && mapData !== null
    ) as MhcFeatureCollection["features"];
};
