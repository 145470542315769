"use client";

import { Dialog, Stack, Typography } from "@mui/material";

import { useNavigationStore } from "common/state/useNavigationStore";
import { StateLoadingIndicator } from "common/components/GeoMap/StateLoadingIndicator";

const PageLoadingIndicator: React.FC<{
  message?: string;
}> = ({ message = "Loading" }) => {
  const { isNavigating } = useNavigationStore((store) => store);
  if (!isNavigating) return null;
  return (
    <Dialog open={true}>
      <Stack gap={3} justifyContent="center" alignItems="center" px={8} py={5}>
        <StateLoadingIndicator
          hasIndicatorBackground={false}
          loading={true}
          indicatorHeight={75}
          height={90}
          width={50}
        />
        <Typography>{message}</Typography>
      </Stack>
    </Dialog>
  );
};

export default PageLoadingIndicator;
