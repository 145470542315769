import React from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Box, Link, Stack, Typography } from "@mui/material";

import { STATIC_LOCATION_IDS } from "./constants";
import { humanize, titleize } from "common/util/helpers";

import { StyledList } from "common/components/lists/StyledList";

interface Props {
  locationClickCallback?: (id: string) => void;
}

const TopicTableNotes: React.FC<Props> = ({ locationClickCallback }) => {
  return (
    <>
      <Typography variant="h3" mb={2}>
        Notes
      </Typography>
      <Stack gap={2}>
        <Box>
          <Typography variant="body1" mb={1}>
            Pages may load slowly. During deployment only pages for the following locations are
            built with all the necessary data to display the page quickly:
          </Typography>
          <StyledList>
            {STATIC_LOCATION_IDS.map((id) => (
              <li key={id}>
                <Link href="#" onClick={() => locationClickCallback?.(id)}>
                  {titleize(humanize(id))}
                </Link>
              </li>
            ))}
          </StyledList>
        </Box>
        <Typography variant="body1">
          Pages marked with <FiberNewIcon /> are newly implemented within the new frontend. Other
          pages may not be accessible depending on the environment (staging, demo, preview, etc.).
        </Typography>
        <Typography variant="body1">
          Pages highlighted yellow and marked with <PersonSearchIcon /> are currently in review and
          pending public release.
        </Typography>
        <Typography variant="body1">
          Pages marked with <EngineeringIcon /> are currently in development and not quite yet ready
          for client review.
        </Typography>
      </Stack>
    </>
  );
};

export default TopicTableNotes;
