import { AlertTitle, Link, Stack, Typography } from "@mui/material";

import { MhcNote } from "graphqlApi/types";

import { MhcAlert } from "./MhcAlert";

export type NoteProps = MhcNote & {
  id?: string;
};

const Note: React.FC<NoteProps> = ({ severity, title, body, url }) => (
  <MhcAlert severity={severity} sx={{ zIndex: 1, "& .MuiAlert-message": { width: "100%" } }}>
    <Stack direction="row" flex={1} flexGrow={1} gap={2}>
      <Stack flex={1} flexGrow={1}>
        {title && <AlertTitle sx={{ mb: 0 }}>{title}</AlertTitle>}
        <Typography>{body}</Typography>
      </Stack>
      {url && (
        <Stack flex={1} flexGrow={0} width="min-content">
          <Link
            href={url}
            sx={{
              backgroundColor: "brand.main",
              color: "white",
              borderRadius: "5px",
              my: "auto",
              px: 1.2,
              py: 0.8
            }}
          >
            <Typography variant="h5" fontWeight="700">
              Go
            </Typography>
          </Link>
        </Stack>
      )}
    </Stack>
  </MhcAlert>
);

export default Note;
