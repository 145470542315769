import React from "react";
import { Skeleton, Stack } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION } from "layout/configuration";

interface Props {
  mb?: number;
  height?: number;
  includeLabel?: boolean;
  minWidth?: string | number;
}

export const LoadingInput: React.FC<Props> = ({
  mb = 0,
  height = 60,
  includeLabel = false,
  minWidth = "none"
}) => (
  <Stack gap={0.75} minWidth={minWidth}>
    {includeLabel && <Skeleton sx={{ width: "65%", height: 40 }} />}
    <Skeleton
      height={height}
      width="100%"
      animation={DEFAULT_SKELETON_ANIMATION}
      sx={{ mb, transform: "none" }}
    />
  </Stack>
);
