import { devStaticPathIds } from "common/util/staticPaths/staticPathIds";

export const DOMAINS_WITH_ALL_TOPICS = [
  "myhealthycommunity.dhss.delaware.gov",
  "delaware-ephtn-frontend.vercel.app",
  "frontend-demo.de-mhc.com",
  "frontend-staging.de-mhc.com"
];

export const STATIC_LOCATION_IDS = Object.values(devStaticPathIds).flat();
