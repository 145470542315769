"use client";

import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";

// variant prop seems to cause issues with mui-picker
export interface MhcTextFieldProps extends Partial<Omit<TextFieldProps, "error">> {
  htmlId: string;
  name?: string;
  label?: React.ReactNode;
  preventAutocomplete?: boolean;
  preventDefaultEnterKey?: boolean;
  error?: string;
}

export const MhcTextField: React.FC<MhcTextFieldProps> = ({
  htmlId,
  inputProps = {},
  InputLabelProps,
  InputProps,
  label,
  hiddenLabel,
  error,
  margin = "normal",
  variant,
  fullWidth = true,
  helperText,
  sx,
  ...props
}) => {
  return (
    <TextField
      variant="outlined"
      id={htmlId}
      fullWidth={fullWidth}
      label={hiddenLabel ? undefined : label}
      margin={margin}
      error={!!error}
      helperText={error ? error : helperText}
      {...props}
      inputProps={{
        ...inputProps,
        ...(label ? { "aria-label": String(label) } : {})
      }}
      sx={{ ...sx }}
      InputProps={{
        notched: false,
        sx: ({ palette }) => ({
          "label + &": {
            marginTop: 0
          },
          backgroundColor: palette.common.white
        }),
        ...InputProps
      }}
      InputLabelProps={{
        ...InputLabelProps,
        hidden: hiddenLabel,
        shrink: true,
        variant: "standard",
        sx: ({ typography, palette }) => ({
          fontWeight: typography.fontWeightMedium,
          color: palette.text.primary,
          fontSize: typography.subtitle2.fontSize,
          transform: "none",
          position: "relative",
          whiteSpace: "normal"
        })
      }}
    />
  );
};
